import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MailDetailsService } from 'app/shared/mail-details/mail.details.service';
import { mailStates } from 'app/shared/mail-details/mail-details.types';
import { TranslatorService } from 'app/core/translator';

@Component({
  selector: 'mail-details',
  templateUrl: './mail-details.component.html',
  styleUrls: ['./mail-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailDetailsComponent implements OnInit {

  // ------------------------------------------------------------------------------
  // @ Attributes
  // ------------------------------------------------------------------------------

  states = mailStates;

  isLoading: boolean = true;
  // Receive array of uuid to return details of each
  notifications: string[] = [];
  details: any[] = [];

  // ------------------------------------------------------------------------------
  // @ Constructor
  // ------------------------------------------------------------------------------

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<MailDetailsComponent>,
    public translator: TranslatorService,
    private _mailDetailsService: MailDetailsService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) { }

  // ------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // ------------------------------------------------------------------------------

  ngOnInit(): void {
    this.notifications = this.data;
    if (this.notifications?.length) {
      this._mailDetailsService.getMailsDetails(this.notifications)
        .subscribe((response) => {
          this.details = response;
          this.isLoading = false;
          this._changeDetectorRef.markForCheck();
        });
    } else { this.isLoading = false; }
  }

  // ------------------------------------------------------------------------------
  // @ Public methods
  // ------------------------------------------------------------------------------

  /**
   * Download the file
   */
  downloadFile(url: string): any {
    window.open(url, '_blank');
  }

}
