/* eslint-disable curly */
/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { EBiometricsEnvironment, EBiometricsParams, EBiometricsQueue, EBiometricsTheme, startFlow } from '@softcol/biometrics';
import { FuseConfigService } from '@fuse/services/config';

import { SnackbarService } from 'app/core/snackbar';
import { environment } from 'environments/environment';
import { EBiometricsService } from './e-biometrics.service';

@Component({
  selector: 'e-biometrics',
  templateUrl: './e-biometrics.component.html',
  styleUrls: ['./e-biometrics.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class EBiometricsComponent implements OnInit, OnDestroy {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  isLoading = true;

  attempts = 3;
  currentAttempt = 0;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matDialogRef: MatDialogRef<EBiometricsComponent>,
    private _fuseConfigService: FuseConfigService,
    private _snackbar: SnackbarService,
    private _eBiometricsService: EBiometricsService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    if (this.data && this.data.signer && this.data.provider && this.data.id)
      this._startBiometrics();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private _startBiometrics(): void {
    this._fuseConfigService.config$.pipe(
      takeUntil(this._unsubscribeAll),
      switchMap((config) => {
        return this._eBiometricsService.countTransaction(this.data.id).pipe(
          tap((canActivate) => {
            if (canActivate) {
              startFlow({
                target: 'ebm',
                account: environment.biometrics.account,
                params: this._buildParams(this.data.signer),
                queue: EBiometricsQueue.simple,
                environment: environment.production ? EBiometricsEnvironment.production : EBiometricsEnvironment.development,
                theme: config.scheme === 'dark' ? EBiometricsTheme.dark : EBiometricsTheme.light,
                provider: this.data.provider,
                onRetry: () => this._onRetry(),
                onSuccess: (response) => this._onSuccess(response),
                onError: (error) => this._onError(error),
              });
            } else this._onError({message: 'Se ha superado el número máximo de intentos en la verficiación de identidad.'})
          }),
        );
      })
    )
    .subscribe();
  }

  private _onSuccess(response: any): void {
    setTimeout(() => {
      this._matDialogRef.close(response);
    }, 2000);
  }

  private _onError(error: any): void {
    this._snackbar.showError(error.message);
    this._matDialogRef.close(null);
  }

  private _onRetry(): void {
    this.currentAttempt++;
    if (this.currentAttempt >= 3) {
      this._matDialogRef.close(null);
      this._snackbar.showError('Inténtelo nuevamente');
    }
  }

  private _buildParams(signer: any): EBiometricsParams {
    return {
      name: signer.names,
      lastName: signer.lastNames,
      documentType: signer.documentType,
      documentNumber: Number(signer.id),
      countryCode: '+57',
      phone: signer.phoneClear ? parseInt(signer.phoneClear) : 9999999999,
      email: signer.email,
    };
  }
}
