import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FiltersBarService {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  private _filters: BehaviorSubject<any> = new BehaviorSubject(null);

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for filters
   */
  get filters$(): Observable<any> {
    return this._filters.asObservable();
  }

  /**
   * Setter for filters
   */
  set filters(value: any) {
    this._filters.next(value);
  }
}
