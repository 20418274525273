/* eslint-disable curly */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { environment } from 'environments/environment';

import { UserService } from 'app/core/user';
import { AccountService } from 'app/core/account';
import { TranslatorService } from 'app/core/translator';
import { Pagination, Query } from 'app/core/models';
import { AuditLog, auditOperations } from 'app/modules/audit-logs/audit-logs.types';

@Injectable({ providedIn: 'root' })
export class AuditLogsService {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  private _auditLog: BehaviorSubject<AuditLog> = new BehaviorSubject(null);
  private _auditLogs: BehaviorSubject<AuditLog[]> = new BehaviorSubject(null);
  private _pagination: BehaviorSubject<Pagination> = new BehaviorSubject(null);

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _translator: TranslatorService,
    private _accountService: AccountService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Getters & Setters
  // -----------------------------------------------------------------------------------------------------

  get auditLog$(): Observable<AuditLog> { return this._auditLog.asObservable(); }
  set auditLog(value: AuditLog) { this._auditLog.next(value); }
  get auditLogs$(): Observable<AuditLog[]> { return this._auditLogs.asObservable(); }
  set auditLogs(value: AuditLog[]) { this._auditLogs.next(value); }
  get pagination$(): Observable<Pagination> { return this._pagination.asObservable(); }
  set pagination(value: Pagination) { this._pagination.next(value); }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get Audit logs
   */
  getAuditLogs(query: Query): Observable<any> {
    return this._accountService.account$.pipe(
      take(1),
      switchMap((account) => {
        if (!query.indexName) {
          query.indexName = 'account_index';
          query.keyValue = { accountUuid: account.accountUuid };
        }
        return this._httpClient
          .post<any>(`${environment.api.account}/auditLogService`, query, { params: { type: 'artificial' } })
          .pipe(
            map((response) => {
              const logs = response.results.map((item: any) => item.element);
              this._auditLogs.next(logs);
              this._pagination.next({ ...response, size: query.total ?? 10 });
            })
          );
      })
    );
  }

  /**
   * Get AuditLog by ID
   */
  getAuditLogById(uuid: string): Observable<any> {
    return this.auditLogs$.pipe(
      take(1),
      map((auditLogs) => {
        if (!auditLogs) return null;
        const auditLog = auditLogs.find((item) => item.uuid === uuid) || null;
        this._auditLog.next(auditLog);
        return auditLog;
      }),
      switchMap((auditLog) => {
        if (!auditLog) return throwError(`Could not found audit log with uuid of ${uuid}!`);
        return of(auditLog);
      })
    );
  }

  /**
   * Create an audit log
   */
  createAuditLog(operation: string, additionalInfo: any = null): Observable<any> {
    return this._accountService.account$.pipe(
      take(1),
      switchMap((account) =>
        this._userService.user$.pipe(
          take(1),
          switchMap((user) =>
            this._httpClient.get<any>(`${environment.api.proApi}`).pipe(
              switchMap((details) => {
                const description = this._generateDescription(operation, user, account, additionalInfo);
                const auditLog = {
                  operation, details, description, user: user.attributes.email,
                  eventDate: new Date(), accountUuid: account?.accountUuid,
                };
                return this._httpClient.post(`${environment.api.account}/auditLogService`, auditLog);
              })
            )
          )
        )
      )
    );
  }

  /**
   * Create an audit log
   */
  createAuditLogNoAuth(operation: string, additionalInfo: any = null): Observable<any> {
    return this._httpClient.get<any>(`${environment.api.proApi}`).pipe(
      switchMap((details) => {
        const description = this._generateDescription(operation, null, null, additionalInfo);
        const auditLog = {
          operation, details, description, user: additionalInfo['user'],
          eventDate: new Date(), accountUuid: additionalInfo['account'],
          documentUuid: additionalInfo['documentUuid'], order: additionalInfo['order'],
        };
        return this._httpClient.post(`${environment.api.account}/auditLogService`, auditLog);
      })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Generate the description
   *
   * @private
   */
  private _generateDescription(operation: string, user: any, account: any, additionalInfo: any): string {
    let description = auditOperations[operation].description;

    if (user) {
      description = description.replace('{user}', user.attributes.email);
    }

    if (account) {
      description = description.replace('{account}', account.name);
      description = description.replace('{role}', this._translator.translate(account.selectedRole));
    }

    if (additionalInfo) {
      Object.keys(additionalInfo).forEach((key) => {
        description = description.replace(`{${key}}`, additionalInfo[key]);
      });
    }

    return description;
  }
}
