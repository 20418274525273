import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {  Observable } from 'rxjs';
import {  map, } from 'rxjs/operators';

import { Query, Response } from 'app/core/models';
import { Entity } from 'app/modules/entities/entities.types';
import { environment } from 'environments/environment';

@Injectable()
export class ProfileService {
  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _httpClient: HttpClient,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get entity by uuid
   */
  getEntityByUuid(uuid: string): Observable<Entity> {
    const query: Query = { total: 1, keyValue: { uuid } };
    return this._httpClient
      .post<Response<any>>(`${environment.api.account}/accountService`, query, { params: { type: 'list' } })
      .pipe(
        map((response) => {
          const found = response.results[0].element;
          return found;
        })
      );
  }
}
