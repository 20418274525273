import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';

import { UserService } from 'app/core/user';
import { AccountService } from 'app/core/account';
import { Module } from './navigation.types';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  private _navigation: ReplaySubject<Module[]> = new ReplaySubject(1);

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _accountService: AccountService,
    private _splashService: FuseSplashScreenService,
  ) {
    // Initialize the data
    this._navigation.next(null);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<Module[]> {
    return this._navigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get navigation data
   */
  get(): Observable<Module[]> {
    // Get the account
    return this._accountService.account$.pipe(
      take(1),
      switchMap((account) =>
        // Get the navigation
        this.navigation$.pipe(
          take(1),
          switchMap(() => {
            // Get the navigation from server
            return this._httpClient
              .get<Module[]>('api/common/navigation', { params: { role: account.selectedRole } })
              .pipe(
                tap((response) => {
                  // Update the navigation
                  this._navigation.next(response);
                })
              );
          })
        )
      )
    );
  }

  /** Clear navigation data */
  clear(): void {
    // Update the navigation
    this._navigation.next(null);
  }
}
