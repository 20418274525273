import { environment } from 'environments/environment';
import { SignerState } from './sign.types';

export const basePath = `https://s3.amazonaws.com/${environment.s3.bucket}`;

export const signerStates: SignerState[] = [
  { value: 'SIGNED',        name: 'Ya firmó este documento' },
  { value: 'SIGNING',       name: 'Firmando documento' },
  { value: 'NOT_EXIST',     name: 'El correo electrónico no existe' },
  { value: 'WAIT_FOR_SIGN', name: 'Pendiente por firmar' },
  { value: 'ERROR',         name: 'Error' },
  { value: 'DELAY',         name: 'Demorado' },
];
