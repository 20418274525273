import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { customPaginatorIntl } from 'assets/material-locale/mat-paginator';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';

import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { LottieModule } from 'ngx-lottie';

import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';

import localeEsCo from '@angular/common/locales/es-CO';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { es as EJ2_LOCALE } from 'assets/ej2-locale/es';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { CustomDateAdapter } from 'app/core/config/date.adapter';
import { AppComponent } from 'app/app.component';
import { InitialDataResolver, LookAndFeelResolver } from 'app/app.resolvers';
import { mockApiServices } from 'app/mock-api';
import { appConfig } from 'app/core/config';
import { appRoutes } from 'app/app.routing';

import { MSALGuardConfigFactory } from 'app/MSALGuardConfigFactory';
import { AuthService } from 'app/core/auth/auth.service';

const routerConfig: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  preloadingStrategy: PreloadAllModules,
};

L10n.load({ es: EJ2_LOCALE.es });
registerLocaleData(localeEsCo, 'es-Co');
setCulture('es');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MSALInterceptorConfigFactory = (): MsalInterceptorConfiguration => {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseConfigModule.forRoot(appConfig),
    FuseMockApiModule.forRoot(mockApiServices),

    // Lottie animations
    LottieModule.forRoot({ player: () => import('lottie-web') }),

    // Core module
    CoreModule,

    // Layout module
    LayoutModule,

    // Necessary to import general custom date adapter
    MatNativeDateModule,

    MatDatepickerModule,
  ],
  providers: [
    AuthService,
    InitialDataResolver,
    LookAndFeelResolver,
    { provide: LOCALE_ID, useValue: 'es-Co' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MatPaginatorIntl, useValue: customPaginatorIntl },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },

    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
