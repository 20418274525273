<div class="biometrics flex flex-col max-w-240 md:min-w-140 -m-6">
  <div class="flex flex-col bg-gray-200 dark:bg-gray-700">
    <div class="bg-white dark:bg-gray-900 relative drop-shadow-2xl rounded-2xl p-4 m-3">
      <div class="flex-none sm:flex">
        <div class="flex-auto justify-evenly">

          <!-- Header -->
          <div class="flex items-center justify-between">
            <div class="font-bold text-lg text-primary-800 dark:text-primary-500">Detalle de transacciones</div>
            <button mat-icon-button type="button" (click)="matDialogRef.close()">
              <mat-icon class="text-current" svgIcon="close"></mat-icon>
            </button>
          </div>

          <!-- Divider -->
          <div class="border-b border-dashed my-5"></div>

          <!-- Content -->
          <ng-container *ngIf="!isLoading">
            <ng-container *ngFor="let transaction of data.signer.biometricsResults| keyvalue; let index = index; let first = first; let last = last">
              
              <!-- Circles -->
              <ng-container *ngIf="!first">
                <div class="border-b border-dashed my-5 pt-5">
                  <div class="absolute rounded-full w-5 h-5 bg-gray-200 dark:bg-gray-700 -mt-2 -left-2"></div>
                  <div class="absolute rounded-full w-5 h-5 bg-gray-200 dark:bg-gray-700 -mt-2 -right-2"></div>
                </div>
              </ng-container>

              <!-- Transaction -->
              <div class="flex flex-col md:flex-row mb-3 md:mt-0">

                <!-- Details -->
                <div class="flex flex-col border-b md:border-b-0 md:border-r md:pr-4">
                  <div class="w-full flex-none text-md text-primary-800 dark:text-primary-500 font-bold leading-none">
                    Transacción {{index + 1}}
                  </div>
                  <div class="mt-3">
                    <div class="flex-auto text-sm my-1">
                      <span class="text-gray-500 mr-1">Estado:</span>
                      <span class="font-semibold">{{transaction.value.state === 'SUCCESSFUL' ? 'Exitosa' : 'Fallida'}}</span>
                    </div>
                    <div class="flex-auto text-sm my-1">
                      <span class="text-gray-500 mr-1">Identificador:</span>
                      <span class="font-semibold">{{transaction.value.id}}</span>
                    </div>
                  </div>
                </div>

                <!-- Actions -->
                <div class="flex flex-col mx-auto mt-3 md:mt-0 justify-center items-center">
                  <button
                    mat-stroked-button
                    color="primary"
                    (click)="downloadReport(transaction.value.id)">
                    Descargar reporte
                  </button>
                </div>

              </div>

            </ng-container>
          </ng-container>

          <!-- Loader -->
          <ng-container *ngIf="isLoading">
            <div class="flex flex-auto items-center justify-center p-8 sm:p-10">
              <mat-progress-spinner color="primary" mode="indeterminate" [diameter]="80"></mat-progress-spinner>
            </div>
          </ng-container>

          <!-- Powered by -->
          <div class="flex flex-col py-5 justify-center text-sm">
            <div class="flex items-center my-1 justify-center">
              <span class="mr-3 w-auto h-9">
                <img
                  src="https://en.software-colombia.com/wp-content/uploads/2021/11/eLogicBiometrics1.png"
                  class="h-9 p-1"
                  alt="eBiometrics logo"
                />
              </span>
              <h2 class="font-medium">
                <span class="text-secondary text-xs mr-1">Powered by</span>
                <a class="hover:text-primary" href="https://en.software-colombia.com/services/biometrics/" target="_blank">eBiometrics</a>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
