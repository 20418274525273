import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PagesLoadedEvent } from 'ngx-extended-pdf-viewer';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';

@Component({
  selector: 'webviewer',
  templateUrl: './webviewer.component.html',
  styleUrls: ['./webviewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'webviewer'
})
export class WebViewerComponent implements OnInit, OnDestroy {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  showButtons = false;
  pdf: any = { zoom: 'page-width', page: 1, total: 1 };

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WebViewerComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    private _mediaWatcherService: FuseMediaWatcherService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    // Subscribe to media changes
    this._mediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        this.showButtons = matchingAliases.includes('sm');
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * On page change
   */
  async onPageChange(page: number): Promise<void> {
    this.pdf.page = Number(page);
  }

  /**
   * On document load complete
   */
  async onLoadComplete(pdf: PagesLoadedEvent): Promise<void> {
    this.pdf.total = pdf.pagesCount;
  }
}
