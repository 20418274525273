import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SignMockApi {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Sign - POST
    // -----------------------------------------------------------------------------------------------------

    /**
     * Read the given file as mock-api url
     */
    const readAsDataURL = (file: File): Promise<any> =>
      // Return a new promise
      new Promise((resolve, reject) => {
        // Create a new reader
        const reader = new FileReader();

        // Resolve the promise on success
        reader.onload = (): void => {
          resolve(reader.result);
        };

        // Reject the promise on error
        reader.onerror = (e): void => {
          reject(e);
        };

        // Read the file as the
        reader.readAsDataURL(file);
      });

    this._fuseMockApiService.onPost('api/signature').reply(({ request }) => {
      // Get the signature and graphic
      const signature = request.body.signature;
      const graphic = request.body.graphic;

      return from(readAsDataURL(graphic)).pipe(
        map((path) => {
          // Update the signature
          signature.graphic = path;

          // Return the response
          return [200, signature];
        })
      );
    });
  }
}
