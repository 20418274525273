export class ProjectVersion {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  public readonly full: string;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(public version: string, public date: string) {
    this.full = 'Versión ' + version + ' - ' + date;
  }
}
