 /* eslint-disable @typescript-eslint/explicit-function-return-type */
import {Module} from 'app/core/navigation';

export const navigation: { [key: string]: Module[] } = {
  administrador: [
    {
      id: 'entities',
      title: 'Entidades',
      type: 'basic',
      icon: 'business',
      link: '/entities',
    },
    {
      id: 'users',
      title: 'Usuarios',
      type: 'basic',
      icon: 'people',
      link: '/users',
    },
    {
      id: 'reports',
      title: 'Reportes',
      type: 'basic',
      icon: 'description',
      link: '/reports',
    },
    {
      id: 'automatic-reports',
      title: 'Reportes automáticos',
      type: 'basic',
      icon: 'text_snippet',
      link: '/automatic-reports',
    },
    {
      id: 'statistics',
      title: 'Estadísticas',
      type: 'basic',
      icon: 'analytics',
      link: '/statistics',
    },
    {
      id: 'my-certificate-profiles',
      title: 'Mis perfiles de certificado',
      type: 'basic',
      icon: 'history_edu',
      link: '/my-certificate-profiles',
    },
    {
      id: 'manage-certificate',
      title: 'Gestionar certificado',
      type: 'basic',
      icon: 'manage_accounts',
      link: '/manage-certificate',
    },
    {
      id: 'look-and-feel',
      title: 'Personalizar',
      type: 'basic',
      icon: 'format_paint',
      link: '/look-and-feel',
    },
    {
      id: 'interoperability',
      title: 'Interoperabilidad',
      type: 'basic',
      icon: 'notifications_active',
      link: '/interoperability',
    },
    {
      id: 'properties',
      title: 'Propiedades',
      type: 'basic',
      icon: 'settings',
      link: '/account-properties',
    },
    {
      id: 'plans',
      title: 'Planes',
      type: 'basic',
      icon: 'tune',
      link: '/plans',
      hidden: () => true
    },
    {
      id: 'my-organization',
      title: 'Mi organización',
      type: 'basic',
      hidden: () => true,
      link: '/my-organization',
    },
  ],
  superadministrador: [
    {
      id: 'properties',
      title: 'Propiedades',
      type: 'basic',
      icon: 'settings',
      link: '/properties',
    },
    {
      id: 'certificate-profiles',
      title: 'Perfiles de certificado',
      type: 'basic',
      icon: 'history_edu',
      link: '/certificate-profiles',
    },
    {
      id: 'report-logics',
      title: 'Lógicas de reporte',
      type: 'basic',
      icon: 'category',
      link: '/report-logics',
    },
    {
      id: 'notification-logics',
      title: 'Lógicas de notificación',
      type: 'basic',
      icon: 'campaign',
      link: 'notification-logics',
    },
    {
      id: 'my-organization',
      title: 'Mi organización',
      type: 'basic',
      hidden: () => true,
      link: '/my-organization',
    },
  ],
  propietario: [
    {
      id: 'records',
      title: 'Expedientes',
      type: 'basic',
      icon: 'source',
      link: '/records',
    },
    {
      id: 'templates',
      title: 'Plantillas',
      type: 'basic',
      icon: 'list_alt',
      link: '/templates',
    },
    {
      id: 'workflows',
      title: 'Flujos de firma',
      type: 'basic',
      icon: 'history_edu',
      link: '/workflows',
    },
    {
      id: 'contracts',
      title: 'Contratos electrónicos',
      type: 'basic',
      icon: 'receipt_long',
      link: '/contracts',
    },
    {
      id: 'promissory-notes',
      title: 'Pagarés electrónicos',
      type: 'collapsable',
      icon: 'monetization_on',
      children: [
        {
          id: 'promissory-notes',
          title: 'Mis pagarés electrónicos',
          type: 'basic',
          link: 'promissory-notes/account',
        },
        {
          id: 'endorsed-promissory-notes',
          title: 'Pagarés electrónicos endosados',
          type: 'basic',
          link: 'promissory-notes/endorsed',
        },
        {
          id: 'approve-promissory-notes',
          title: 'Aceptar pagarés electrónicos',
          type: 'basic',
          link: 'promissory-notes/approve',
        },
      ]
    },
    {
      id: 'my-organization',
      title: 'Mi organización',
      type: 'basic',
      hidden: () => true,
      link: '/my-organization',
    },
  ],
  firmante: [
    {
      id: 'workflows',
      title: 'Mis flujos de firma',
      type: 'basic',
      icon: 'history_edu',
      link: '/my-workflows',
    },
    {
      id: 'contracts',
      title: 'Mis contratos electrónicos',
      type: 'basic',
      icon: 'receipt_long',
      link: '/my-contracts',
    },
    {
      id: 'promissory-notes',
      title: 'Mis pagarés electrónicos',
      type: 'basic',
      icon: 'monetization_on',
      link: '/my-promissory-notes',
    },
    {
      id: 'manage-certificate',
      title: 'Gestionar certificado',
      type: 'basic',
      icon: 'manage_accounts',
      link: '/manage-certificate',
    },
    {
      id: 'my-organization',
      title: 'Mi organización',
      type: 'basic',
      hidden: () => true,
      link: '/my-organization',
    },
  ],
  reporteador: [
    {
      id: 'reports',
      title: 'Reportes',
      type: 'basic',
      icon: 'description',
      link: '/reports',
    },
    {
      id: 'automatic-reports',
      title: 'Reportes automáticos',
      type: 'basic',
      icon: 'text_snippet',
      link: '/automatic-reports',
    },
    {
      id: 'my-organization',
      title: 'Mi organización',
      type: 'basic',
      hidden: () => true,
      link: '/my-organization',
    },
  ],
  auditor: [
    {
      id: 'audit-logs',
      title: 'Logs de auditoría',
      type: 'basic',
      icon: 'description',
      link: '/audit-logs',
    },
    {
      id: 'my-organization',
      title: 'Mi organización',
      type: 'basic',
      hidden: () => true,
      link: '/my-organization',
    },
  ]
};
