/*eslint-disable @typescript-eslint/naming-convention*/
export interface AuditLog {
  uuid?: string;
  accountUuid?: string;
  user?: string;
  operation?: string;
  eventDate?: Date;
  description?: string;
  details?: any;
}

export enum AuditOperations {
  // Notification operations
  MARK_AS_READ = 'MARK_AS_READ',

  // Authentication operations
  AUTHENTICATION           = 'AUTHENTICATION',

  // Automatic reports operations
  CREATED_AUTOMATIC_REPORT = 'CREATED_AUTOMATIC_REPORT',
  EDITED_AUTOMATIC_REPORT  = 'EDITED_AUTOMATIC_REPORT',
  DELETED_AUTOMATIC_REPORT = 'DELETED_AUTOMATIC_REPORT',

  // Callback operations
  EDITED_CALLBACK          = 'EDITED_CALLBACK',

  // Entity operations
  CREATED_ENTITY           = 'CREATED_ENTITY',
  EDITED_ENTITY            = 'EDITED_ENTITY',
  DELETED_ENTITY           = 'DELETED_ENTITY',

  // Look & feel operations
  EDITED_LOOK_AND_FEEL     = 'EDITED_LOOK_AND_FEEL',

  // Plans operations
  CREATED_PLAN             = 'CREATED_PLAN',
  EDITED_PLAN              = 'EDITED_PLAN',
  INACTIVATED_PLAN         = 'INACTIVATED_PLAN',
  ACTIVATED_PLAN           = 'ACTIVATED_PLAN',

  // Promissory notes operations
  CREATED_PROMISE_NOTE     = 'CREATE_PROMISE_NOTE',
  EDITED_PROMISE_NOTE      = 'EDITED_PROMISE_NOTE',
  ARCHIVED_PROMISE_NOTE    = 'ARCHIVED_PROMISE_NOTE',
  EXECUTED_PROMISE_NOTE    = 'EXECUTED_PROMISE_NOTE',
  ENDORSE_PROMISE_NOTE     = 'ENDORSE_PROMISE_NOTE',
  DELETED_PROMISE_NOTE     = 'DELETED_PROMISE_NOTE',
  CALLBACK_EXECUTION       = 'CALLBACK_EXECUTION',

  // Folders operations
  CREATED_FOLDER           = 'CREATED_FOLDER',
  EDITED_FOLDER            = 'EDITED_FOLDER',
  DELETED_FOLDER           = 'DELETED_FOLDER',

  // Users operations
  CREATED_USER             = 'CREATED_USER',
  EDITED_USER              = 'EDITED_USER',
  DELETED_USER             = 'DELETED_USER',
  INACTIVATED_USER         = 'INACTIVATED_USER',

  // Workflows operations
  CREATED_WORKFLOW         = 'CREATED_WORKFLOW',
  EDITED_WORKFLOW          = 'EDITED_WORKFLOW',
  DELETED_WORKFLOW         = 'DELETED_WORKFLOW',
  ARCHIVED_WORKFLOW        = 'ARCHIVED_WORKFLOW',

  // Sign operations
  VIEW_DOCUMENT            = 'VIEW_DOCUMENT',
  SIGN_DOCUMENT            = 'SIGN_DOCUMENT',
  APPROVE_DOCUMENT         = 'APPROVE_DOCUMENT',
}

export const auditOperations: any = {
  // Notification operations
  MARK_AS_READ             : { name: 'Edición de notificación',           description: 'Ha marcado como leída la notificación con identificador {uuid}' },

  // Authentication operations
  AUTHENTICATION           : { name: 'Autenticación en la plataforma',    description: 'Se ha autenticado en la plataforma utilizando el rol de {role}' },

  // Automatic reports operations
  CREATED_AUTOMATIC_REPORT : { name: 'Creación de reporte automático',    description: 'Ha creado el reporte automático con nombre {name}' },
  EDITED_AUTOMATIC_REPORT  : { name: 'Edición de reporte automático',     description: 'Ha editado el reporte automático con nombre {name}' },
  DELETED_AUTOMATIC_REPORT : { name: 'Eliminacion de reporte automático', description: 'Ha eliminado el reporte automático con nombre {name}' },

  // Callback operations
  EDITED_CALLBACK          : { name: 'Edición de interoperabilidad',      description: 'Ha editado las propiedades de interoperabilidad en la cuenta' },

  // Entity operations
  CREATED_ENTITY           : { name: 'Creación de cuenta',                description: 'Ha creado la cuenta con nombre {name}' },
  EDITED_ENTITY            : { name: 'Edición de cuenta',                 description: 'Ha editado la cuenta con nombre {name}' },
  DELETED_ENTITY           : { name: 'Eliminación de cuenta',             description: 'Ha eliminado la cuenta con nombre {name}' },

  // Look & feel operations
  EDITED_LOOK_AND_FEEL     : { name: 'Edición de look and feel',          description: 'Ha editado las propiedades del look and feel en la cuenta' },

  // Plans operations
  CREATED_PLAN             : { name: 'Creación de plan',                  description: 'Ha creado un plan de {type} para la cuenta {accountName}' },
  EDITED_PLAN              : { name: 'Edición de plan',                   description: 'Ha editado un plan de {type} para la cuenta {accountName}' },
  INACTIVATED_PLAN         : { name: 'Inactivación de plan',              description: 'Ha inactivado un plan de {type} para la cuenta {accountName}' },
  ACTIVATED_PLAN           : { name: 'Activación de plan',                description: 'Ha activado un plan de {type} para la cuenta {accountName}' },

  // Promissory notes operations
  CREATED_PROMISE_NOTE     : { name: 'Creación de pagaré',                description: 'Ha creado el pagaré con identificador {uuid}' },
  EDITED_PROMISE_NOTE      : { name: 'Endoso de pagaré',                  description: 'Ha editado el pagaré con identificador {uuid}' },
  ARCHIVED_PROMISE_NOTE    : { name: 'Archivación de pagaré',             description: 'Ha archivado el pagaré con identificador {uuid}' },
  EXECUTED_PROMISE_NOTE    : { name: 'Ejecución de pagaré',               description: 'Ha ejecutado el pagaré con identificador {uuid}' },
  ENDORSE_PROMISE_NOTE     : { name: 'Endoso de pagaré',                  description: 'Ha endosado el pagaré con identificador {uuid}' },
  DELETED_PROMISE_NOTE     : { name: 'Cancelación de pagaré',             description: 'Ha cancelado el pagaré con identificador {uuid}' },
  CALLBACK_EXECUTION       : { name: 'Ejecución de callback',             description: 'Ejecución de callback al finalizar flujo de firma' },

  // Folders operations
  CREATED_FOLDER           : { name: 'Creación de expediente',            description: 'Ha creado el expediente con nombre {name}' },
  EDITED_FOLDER            : { name: 'Edición de expediente',             description: 'Ha editado el expediente con nombre {name}' },
  DELETED_FOLDER           : { name: 'Eliminación de expediente',         description: 'Ha eliminado el expediente con nombre {name}' },

  // Users operations
  CREATED_USER             : { name: 'Creación de usuario',               description: 'Ha creado el usuario con correo electrónico {email}' },
  EDITED_USER              : { name: 'Edición de usuario',                description: 'Ha editado el usuario con correo electrónico {email}' },
  DELETED_USER             : { name: 'Eliminación de usuario',            description: 'Ha eliminado el usuario con correo electrónico {email}' },
  INACTIVATED_USER         : { name: 'Inactivación de usuario',           description: 'Ha inactivado el usuario con correo electrónico {email}' },

  // Workflows operations
  CREATED_WORKFLOW         : { name: 'Creación de flujo de firma',        description: 'Ha creado el flujo de firma con identificador {uuid}' },
  EDITED_WORKFLOW          : { name: 'Endoso de flujo de firma',          description: 'Ha editado el flujo de firma con identificador {uuid}' },
  ARCHIVED_WORKFLOW        : { name: 'Archivación de flujo de firma',     description: 'Ha archivado el flujo de firma con identificador {uuid}' },

  // Sign operations
  VIEW_DOCUMENT            : { name: 'Visualización de documento',        description: 'Ha visualizado el {type} con identificador {uuid}' },
  SIGN_DOCUMENT            : { name: 'Firma de documento',                description: 'Ha firmado el {type} con identificador {uuid}' },
  APPROVE_DOCUMENT         : { name: 'Aprobación de documento',           description: 'Ha aprobado el {type} con identificador {uuid}' },
};
