<div class="flex flex-col flex-auto max-h-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
      <div class="text-lg font-medium">Visualización del documento</div>
      <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon class="text-current" svgIcon="close"></mat-icon>
      </button>
    </div>

    <!-- Document -->
    <div class="flex-auto overflow-hidden">

      <!-- PDF viewer -->
      <ngx-extended-pdf-viewer
        pageViewMode="infinite-scroll"
        [logLevel]="0"
        [src]="data.document"
        [page]="pdf.page"
        [(zoom)]="pdf.zoom"
        [handTool]="false"
        [textLayer]="false"
        [sidebarVisible]="false"
        [showUnverifiedSignatures]="true"
        [customToolbar]="customToolbar"
        (pageChange)="onPageChange($event)"
        (pagesLoaded)="onLoadComplete($event)"
      ></ngx-extended-pdf-viewer>
    </div>
</div>

<!-- Toolbar -->
<ng-template #customToolbar>
  <div class="relative flex flex-0 items-center justify-between h-13 px-4 bg-card border-b -mt-px">

    <!-- Navigation -->
    <div class="flex items-center mx-auto">

      <!-- First page -->
      <ng-container *ngIf="showButtons">
        <button mat-icon-button id="firstPage" matTooltip="Primera página">
          <mat-icon svgIcon="first_page"></mat-icon>
        </button>
      </ng-container>

      <!-- Previous page -->
      <button mat-icon-button id="previous" matTooltip="Página anterior">
        <mat-icon svgIcon="arrow_back"></mat-icon>
      </button>

      <!-- Any page -->
      <ng-container *ngIf="showButtons">
        <div class="flex items-center justify-center mx-2.5 font-medium leading-5">
          <input id="pageNumber" class="w-12 rounded-md p-1 flex items-center border focus:border-primary" [(ngModel)]="pdf.page" />
          <span class="mx-1 text-hint">/</span>
          <span>{{pdf.total}}</span>
        </div>
      </ng-container>

      <!-- Next page -->
      <button mat-icon-button id="next" matTooltip="Página siguiente">
        <mat-icon svgIcon="arrow_forward"></mat-icon>
      </button>

      <!-- Last page -->
      <ng-container *ngIf="showButtons">
        <button mat-icon-button id="lastPage" matTooltip="Última página">
          <mat-icon svgIcon="last_page"></mat-icon>
        </button>
      </ng-container>

      <!-- Divider -->
      <div class="bg-gray-300 w-px h-7 mx-2"></div>

      <!-- Zoom in -->
      <button mat-icon-button id="zoomIn" matTooltip="Ampliar">
        <mat-icon svgIcon="add_circle"></mat-icon>
      </button>

      <!-- Zoom out -->
      <button mat-icon-button id="zoomOut" matTooltip="Reducir">
        <mat-icon svgIcon="remove_circle"></mat-icon>
      </button>
    </div>
  </div>
</ng-template>
