<!-- Alert -->
<fuse-alert
  *ngIf="showDocAlert" class="mt-4 mb-2" appearance="outline"
  [showIcon]="true" [type]="docAlert.type" [@shake]="docAlert.type === 'error'">
  {{docAlert.message}}
</fuse-alert>

<!-- Dropzone -->
<ngx-dropzone
  class="flex flex-row justify-center rounded-lg bg-gray-50 hover:bg-gray-100 dark:bg-black dark:bg-opacity-5 dark:hover:bg-opacity-10 border-gray-300 hover:border-gray-400 dark:border-gray-500 dark:hover:border-gray-400"
  [accept]="accept" [multiple]="multiple" [disableClick]="!enable" (change)="onSelect($event)">
  <ngx-dropzone-label class="flex flex-col items-center text-base text-secondary">
    <mat-icon class="icon-size-14" svgIcon="cloud_upload"></mat-icon>
    <span>Arrastre el archivo aquí o haga click para seleccionarlo.</span>
  </ngx-dropzone-label>
  <ngx-dropzone-preview
    *ngFor="let file of files"
    class="rounded-lg bg-none bg-gray-200 dark:bg-gray-800 hover:bg-none border border-gray-300 dark:border-gray-500"
    [removable]="enable" (removed)="onRemove(file)">
    <ngx-dropzone-label class="flex flex-col text-base text-secondary w-full">
      <div class="flex justify-center mb-2">
        <div class="relative">
          <mat-icon class="icon-size-10 text-hint" svgIcon="insert_drive_file"></mat-icon>
          <div
            class="absolute -right-3 bottom-0 px-1.5 rounded text-sm font-semibold leading-5 text-white bg-"
            [ngClass]="{
              'bg-red-600': file.type === 'application/pdf',
              'bg-blue-600': file.type === 'application/msword' || file.type === 'application/x-java-keystore',
              'bg-green-600': file.type === 'XLS',
              'bg-gray-600': file.type === 'application/x-x509-ca-cert' || file.type === 'application/pkcs10' || file.type === 'application/pkix-cert' || file.type === 'application/x-x509-ca-cert' || file.type === 'application/x-x509-user-cert' || file.type === 'application/x-pem-file',
              'bg-amber-600': file.type === 'JPG' || file.type === 'application/x-pkcs12',
              'bg-indigo-600': file.type ===  ''
            }">
            {{file.name.substr(file.name.lastIndexOf(".") + 1).toLocaleUpperCase()}}
          </div>
        </div>
      </div>
      <span class="font-medium truncate" [matTooltip]="file.name">
        {{file.name}}
      </span>
      <span class="text-md">{{file.size | filesize}}</span>
    </ngx-dropzone-label>
  </ngx-dropzone-preview>
</ngx-dropzone>
