/* eslint-disable */
export const categories = [
  {
    id: 1,
    name: 'LDAP',
    type: 'category',
  },
];

export const properties = [
  {
    id: 1,
    name: 'path',
    value: 'smb://SCAD/TEST/',
    encrypted: false,
    category: 'LDAP',
  },
  {
    id: 2,
    name: 'username',
    value: 'Administrator@ACTIVOS.TEST.CO',
    encrypted: false,
    category: 'LDAP',
  },
  {
    id: 3,
    name: 'password',
    value: 'DRG;WR@bSB',
    encrypted: false,
    category: 'LDAP',
  },
];
