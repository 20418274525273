/* eslint-disable curly */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config';
import { FuseMockApiUtils } from '@fuse/lib/mock-api';
import { LookAndFeel } from 'app/modules/look-and-feel/look-and-feel.types';
import { AccountService } from 'app/core/account';
import { EntitiesService } from 'app/modules/entities/entities.service';
import { environment } from 'environments/environment';
import { S3Service } from 'app/core/s3';
import { SnackbarService } from 'app/core/snackbar';
import { basePath } from '../sign/sign.constants';
import { AuditLogsService } from 'app/modules/audit-logs/audit-logs.service';
import { AuditOperations } from 'app/modules/audit-logs/audit-logs.types';

@Injectable({ providedIn: 'root' })
export class LookAndFeelService {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  private _lookAndFeel: BehaviorSubject<LookAndFeel> = new BehaviorSubject(
    null
  );

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _httpClient: HttpClient,
    private _fuseConfigService: FuseConfigService,
    private _accountService: AccountService,
    private _entityService: EntitiesService,
    private _s3Service: S3Service,
    private _snackBar: SnackbarService,
    private _auditLogsService: AuditLogsService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  set lookAndFeel(value: LookAndFeel) {
    this._lookAndFeel.next(value);
  }
  get lookAndFeel$(): Observable<LookAndFeel> {
    return this._lookAndFeel.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the look and feel for the current domain
   */
  getLookAndFeel(domain: string = null): Observable<LookAndFeel> {
    /** Check if request is just for consultation */
    let isDomain: boolean = false;

    if (!domain) {
      isDomain = true;
      domain = document.location.hostname;
      domain = domain.split('.')[0];
    }

    return this.lookAndFeel$.pipe(
      take(1),
      map((lookAndFeel) => {
        if (isDomain && lookAndFeel?.subdomain === domain) return lookAndFeel;
        return null;
      }),
      switchMap((lookAndFeel) => {
        if (!lookAndFeel) {
          return this._httpClient
            .get<LookAndFeel>(
              `${environment.api.account}/getLookAndFeelCustomization`,
              { params: { domain } }
            )
            .pipe(
              map((response: LookAndFeel) => {
                response.homeLogo = `${
                  response.homeLogo
                }?${FuseMockApiUtils.guid()}`;
                response.authLogo = `${
                  response.authLogo
                }?${FuseMockApiUtils.guid()}`;
                response.menuLogo = `${
                  response.menuLogo
                }?${FuseMockApiUtils.guid()}`;
                response.notificationLogo = `${
                  response.notificationLogo
                }?${FuseMockApiUtils.guid()}`;

                response.shortName = response.shortName || response.name;
                if (isDomain) {
                  this._fuseConfigService.config = {
                    theme: response.color || 'indigo',
                  };
                  this._lookAndFeel.next(response);
                }
                return response;
              })
            );
        }
        return of(lookAndFeel);
      }),
      switchMap((lookAndFeel) => {
        if (!lookAndFeel)
          return throwError('Could not found lookAndFeel for ' + domain + '!');
        return of(lookAndFeel);
      })
    );
  }

  /**
   * Get current account Look And Feel
   */
  getAccountLookAndFeel(cache: boolean = true): Observable<LookAndFeel> {
    return this._accountService.account$.pipe(
      take(1),
      switchMap((account) =>
        this._entityService.getEntity(account.accountUuid).pipe(
          take(1),
          switchMap((entity) => {
            if (entity.lookAndFeelCustomization && cache) {
              entity.lookAndFeelCustomization.homeLogo = `${
                entity.lookAndFeelCustomization.homeLogo
              }?${FuseMockApiUtils.guid()}`;
              entity.lookAndFeelCustomization.authLogo = `${
                entity.lookAndFeelCustomization.authLogo
              }?${FuseMockApiUtils.guid()}`;
              entity.lookAndFeelCustomization.menuLogo = `${
                entity.lookAndFeelCustomization.menuLogo
              }?${FuseMockApiUtils.guid()}`;
              entity.lookAndFeelCustomization.notificationLogo = `${
                entity.lookAndFeelCustomization.notificationLogo
              }?${FuseMockApiUtils.guid()}`;
              return of(entity.lookAndFeelCustomization);
            } else
              return this.getLookAndFeel(
                entity?.domain ? entity.domain : FuseMockApiUtils.guid()
              );
          })
        )
      )
    );
  }

  /** Save lookAnd Feel */
  saveLookAndFeel(lookAndFeel: LookAndFeel): Observable<any> {
    return this._accountService.account$.pipe(
      take(1),
      switchMap((account) =>
        forkJoin([
          this._setLogos(
            [
              lookAndFeel.homeLogo,
              lookAndFeel.authLogo,
              lookAndFeel.menuLogo,
              lookAndFeel.notificationLogo,
            ],
            account.accountUuid
          ),
          this._entityService.getEntityByUuid(account.accountUuid),
        ])
      ),
      switchMap(([logos, entity]) => {
        lookAndFeel.homeLogo = logos[0];
        lookAndFeel.authLogo = logos[1];
        lookAndFeel.menuLogo = logos[2];
        lookAndFeel.notificationLogo = logos[3];

        const body = {
          lookAndFeelCustomization: lookAndFeel,
          domain: lookAndFeel.subdomain,
        };

        return this._httpClient
          .put<{ id: string }>(
            `${environment.api.account}/accountService/${entity.id}`,
            body
          )
          .pipe(
            map((response) => {
              let domain = document.location.hostname;
              domain = domain.split('.')[0];

              if (domain === lookAndFeel.subdomain) {
                lookAndFeel.homeLogo = `${basePath}/${
                  lookAndFeel.homeLogo
                }?${FuseMockApiUtils.guid()}`;
                lookAndFeel.authLogo = `${basePath}/${
                  lookAndFeel.authLogo
                }?${FuseMockApiUtils.guid()}`;
                lookAndFeel.menuLogo = `${basePath}/${
                  lookAndFeel.menuLogo
                }?${FuseMockApiUtils.guid()}`;
                lookAndFeel.notificationLogo = `${basePath}/${
                  lookAndFeel.notificationLogo
                }?${FuseMockApiUtils.guid()}`;

                this._fuseConfigService.config = {
                  theme: lookAndFeel.color || 'indigo',
                };
                this._lookAndFeel.next(lookAndFeel);
              }

              this._auditLogsService
                .createAuditLog(AuditOperations.EDITED_LOOK_AND_FEEL)
                .subscribe();
              this._snackBar.showSuccess('¡Cambios guardados!');
              return response;
            }),
            catchError((error) => this._handleError(error.error))
          );
      })
    );
  }

  sendAuthorizationMail(): Observable<any> {
    return this.getAccountLookAndFeel(false).pipe(
      switchMap((lookAndFeel) =>
        this._httpClient
          .post<{ message: string; success: boolean }>(
            `${environment.api.account}/resendEmailAuthorizationService`,
            {},
            { params: new HttpParams().set('email', lookAndFeel.email) }
          )
          .pipe(
            map((response) => {
              if (response.success)
                this._snackBar.showSuccess(response.message);
              else this._handleError(response.message);
            }),
            catchError((error) => this._handleError(error.error))
          )
      )
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update logos information
   */
  private _setLogos(logos: string[], accountUuid): Observable<any[]> {
    const labels = ['homeLogo', 'authLogo', 'menuLogo', 'notificationLogo'];

    return forkJoin(
      logos.map((logo: any, index) => {
        // Generate the key
        const key = `${accountUuid}/lookandfeel/logos/${labels[index]}`;

        if (logo.startsWith(basePath)) return of(key);
        else
          return this._s3Service
            .uploadFromBase64(logo, key)
            .pipe(map(() => key));
      })
    );
  }

  private _handleError(error: any): Observable<boolean> {
    this._snackBar.showError(
      error?.message || 'Ha ocurrido un error inesperado'
    );
    return of(false);
  }
}
