import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseDrawerModule } from '@fuse/components/drawer';

import { SharedModule } from 'app/shared/shared.module';
import { LayoutComponent } from './layout.component';
import { EmptyLayoutModule } from './empty/empty.module';
import { ClassicLayoutModule } from './classic/classic.module';

const layoutModules = [
  // Empty
  EmptyLayoutModule,
  // Vertical navigation
  ClassicLayoutModule,
];

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    MatIconModule,
    MatTooltipModule,
    FuseDrawerModule,
    SharedModule,
    ...layoutModules,
  ],
  exports: [LayoutComponent, ...layoutModules],
})
export class LayoutModule {}
