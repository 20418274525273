import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountService } from 'app/core/account';
import { ContentType } from 'app/modules/plans/plans.types';
import { Signer } from 'app/modules/sign/sign.types';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class FlowService {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _httpClient: HttpClient,
    private _accountService: AccountService
  ) {}

  verifyIdentity(
    signer: Signer,
    contentType: ContentType,
    verify: boolean
  ): Observable<{ warning: boolean; message?: string }> {
    if (!verify) {
      return of({ warning: false });
    }
    return this._accountService.account$.pipe(
      take(1),
      switchMap((account) => {
        (signer as any).accountUuid = account.accountUuid;
        (signer as any).contentType = contentType;
        return this._httpClient
          .post<{ warning: boolean; message: string }>(
            `${environment.api.flow}/verifyIdService`,
            signer
          )
          .pipe(catchError((error) => of({ warning: true, message: '!Ocurrió un error al verificar la identidad!'})));
      })
    );
  }
}
