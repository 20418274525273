/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import {map} from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class S3Service {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------



  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(private _httpClient: HttpClient) {
    // Initialize the S3 Client

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Generate PreSigned URL
   */

  private generatePreSignedUrl(key: string): string {

    const request = new XMLHttpRequest();
    request.open("POST", `${environment.api.account}/getS3Url`, false); // `false` makes the request synchronous
    request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    request.send(JSON.stringify({ "key": key}));

    return JSON.parse(request.responseText).url;

  }

  /**
   * Upload an object to Amazon S3 from a file
   */
  public uploadFromFile(file: File, key: string): Observable<any> {

    let url = this.generatePreSignedUrl(key);

    const headers = new HttpHeaders({
      'Content-Type': file.type,
    });

    const req = new HttpRequest(
      'PUT', url, file, { headers: headers }
    );
    return from(this._httpClient.request<any>(req).toPromise());
  }

  /**
   * Upload an object to Amazon S3 from Base64 data
   */
  public uploadFromBase64(base64Data: string, key: string): Observable<any> {

    let url = this.generatePreSignedUrl(key);

    // Prepare the Base64 data
    const file = Buffer.from(base64Data.replace(/^data:image\/\w+;base64,/, ''), 'base64');

    // Get the file type
    const type = base64Data.split(';')[0].split('/')[1];

    const headers = new HttpHeaders({
      'Content-Type': `image/${type}`,
    });

    const req = new HttpRequest(
      'PUT', url, file.buffer, { headers: headers }
    );
    return from(this._httpClient.request<any>(req).toPromise());

  }

  /**
   * Convert docx to pdf
   */
  docxToPdf(key: string, type: string): Observable<string> {
    // Generate the path
    const path = `https://${environment.s3.bucket}.s3.${environment.s3.region}.amazonaws.com/${key}`;

    // Convert to pdf
    return this._httpClient
      .post<{ path: string }>(`${environment.api.flow}/parseDocumentToPDF`, { path, type })
      .pipe(map(
        (response) =>
          // Return the path
          response.path
      ));
  }
}
