<div class="flex flex-col max-w-240 md:min-w-160 max-h-screen -m-6">

  <!-- Content -->
  <div class="flex flex-col flex-auto overflow-y-auto p-6 sm:p-8">
    <ng-container *ngIf="!isLoading; else loading">
      <ng-container *ngIf="isAvailable; else noAvailable">
        <div class="flex flex-col items-center gap-3">

          <!-- Animation -->
          <div class="flex flex-0 items-center justify-center w-44 h-44 rounded-full bg-primary-100 dark:bg-primary-500">
            <ng-lottie [options]="options" width="250px" height="250px"></ng-lottie>
          </div>
          
          <!-- Title -->
          <div class="text-lg text-secondary">
            Espere un momento mientras validamos la huella...
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- Start HClient template -->
<ng-template #noAvailable>
  <div class="flex flex-col items-center gap-2">
    <fingerprint></fingerprint>
    <div class="font-semibold text-lg mt-3">
      ¡Ha ocurrido un error!
    </div>
    <div class="text-secondary">
      No fue posible conectarse con <strong>HClient</strong>. Asegúrese que la aplicación esté
      corriendo en su dispositivo y a continuación haga clic en el botón de reintentar.
    </div>
    <div class="mt-3">
      <button mat-flat-button type="button" color="primary" (click)="startHClient()">
        Reintentar
      </button>
    </div>
  </div>
</ng-template>

<!-- Loading template -->
<ng-template #loading>
  <div class="flex items-center justify-center">
    <mat-progress-spinner 
      color="primary" mode="indeterminate" [diameter]="80">
    </mat-progress-spinner>
  </div>
</ng-template>
