/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Route } from '@angular/router';

import { LayoutComponent } from 'app/layout/layout.component';
import { AuthGuard, NoAuthGuard } from 'app/core/guards';
import { InitialDataResolver, LookAndFeelResolver } from 'app/app.resolvers';

export const appRoutes: Route[] = [
  // Redirect empty path
  { path: '', pathMatch: 'full', redirectTo: 'home' },

  // Redirect signed-in user
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'select-entity' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    resolve: [LookAndFeelResolver],
    component: LayoutComponent,
    data: { layout: 'empty' },
    children: [
      { path: 'sign-in', loadChildren: () => import('./modules/auth/sign-in/sign-in.module').then((m) => m.SignInModule) },
      { path: 'forgot-password', loadChildren: () => import('./modules/auth/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule) },
      { path: 'confirm-account', loadChildren: () => import('./modules/auth/confirm-account/confirm-account.module').then((m) => m.ConfirmAccountModule) },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: [LookAndFeelResolver],
    component: LayoutComponent,
    data: { layout: 'empty' },
    children: [
      { path: 'sign-out', loadChildren: () => import('./modules/auth/sign-out/sign-out.module').then((m) => m.SignOutModule) },
      { path: 'select-entity', loadChildren: () => import('./modules/auth/select-entity/select-entity.module').then((m) => m.SelectEntityModule) },
    ],
  },

  // Landing routes
  {
    path: '',
    resolve: [LookAndFeelResolver],
    component: LayoutComponent,
    data: { layout: 'empty' },
    children: [
      { path: 'home', loadChildren: () => import('./modules/home/home.module').then((m) => m.LandingHomeModule) },
    ],
  },

  // Public routes
  {
    path: 'public',
    resolve: [LookAndFeelResolver],
    component: LayoutComponent,
    data: { layout: 'empty' },
    children: [
      { path: 'sign', loadChildren: () => import('./modules/sign/sign.module').then((m) => m.SignModule) },
      { path: 'changelog', loadChildren: () => import('./modules/changelog/changelog.module').then((m) => m.ChangelogModule) },
      { path: 'my-documents', loadChildren: () => import('./modules/my-documents/my-documents.module').then((m) => m.MyDocumentsModule) },
      { path: 'pdf-builder', loadChildren: () => import('./modules/pdf-builder/pdf-builder.module').then((m) => m.PdfBuilderModule) },
    ],
  },

  // Application routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: { layout: 'classic' },
    resolve: [InitialDataResolver, LookAndFeelResolver],
    children: [
      {
        path: 'entities',
        data: { breadcrumb: 'Entidades' },
        loadChildren: () => import('./modules/entities/entities.module').then((m) => m.EntitiesModule),
      },
      {
        path: 'users',
        data: { breadcrumb: 'Usuarios' },
        loadChildren: () => import('./modules/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'reports',
        data: { breadcrumb: { skip: true } },
        loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: 'report-logics',
        data: { breadcrumb: { skip: true } },
        loadChildren: () => import('./modules/report-logics/report-logics.module').then((m) => m.ReportLogicsModule),
      },
      {
        path: 'notification-logics',
        data: { breadcrumb: { skip: true } },
        loadChildren: () => import('./modules/notification-logics/notification-logics.module').then((m) => m.NotificationLogicsModule),
      },
      {
        path: 'audit-logs',
        data: { breadcrumb: { skip: true } },
        loadChildren: () => import('./modules/audit-logs/audit-logs.module').then((m) => m.AuditLogsModule),
      },
      {
        path: 'interoperability',
        data: { breadcrumb: { skip: true } },
        loadChildren: () => import('./modules/callback/callback.module').then((m) => m.CallbackModule),
      },
      {
        path: 'automatic-reports',
        data: { breadcrumb: { skip: true } },
        loadChildren: () => import('./modules/automatic-reports/automatic-reports.module').then((m) => m.AutomaticReportsModule),
      },
      {
        path: 'workflows',
        data: { breadcrumb: 'Flujos de firma' },
        loadChildren: () => import('./modules/workflows/workflows.module').then((m) => m.WorkflowsModule),
      },
      {
        path: 'my-workflows',
        data: { breadcrumb: 'Mis flujos de firma' },
        loadChildren: () => import('./modules/workflows/workflows.module').then((m) => m.WorkflowsModule),
      },
      {
        path: 'contracts',
        data: { breadcrumb: 'Contratos electrónicos' },
        loadChildren: () => import('./modules/contracts/contracts.module').then((m) => m.ContractsModule),
      },
      {
        path: 'my-contracts',
        data: { breadcrumb: 'Mis contratos electrónicos' },
        loadChildren: () => import('./modules/contracts/contracts.module').then((m) => m.ContractsModule),
      },
      {
        path: 'promissory-notes',
        data: { breadcrumb: 'Pagarés electrónicos' },
        loadChildren: () => import('./modules/promissory-notes/promissory-notes.module').then((m) => m.PromissoryNotesModule),
      },
      {
        path: 'my-promissory-notes',
        data: { breadcrumb: 'Mis pagarés electrónicos' },
        loadChildren: () => import('./modules/promissory-notes/promissory-notes.module').then((m) => m.PromissoryNotesModule),
      },
      {
        path: 'records',
        data: { breadcrumb: 'Expedientes' },
        loadChildren: () => import('./modules/records/records.module').then((m) => m.RecordsModule),
      },
      {
        path: 'templates',
        data: { breadcrumb: 'Templates'},
        loadChildren: () => import('./modules/templates/templates.module').then((m) => m.TemplatesModule),
      },
      {
        path: 'properties',
        loadChildren: () => import('./modules/properties/properties.module').then((m) => m.PropertiesModule),
      },
      {
        path: 'account-properties',
        loadChildren: () => import('./modules/properties/properties.module').then((m) => m.PropertiesModule),
      },
      {
        path: 'example',
        data: { breadcrumb: 'Ejemplo' },
        loadChildren: () => import('./modules/example/example.module').then((m) => m.ExampleModule),
      },
      {
        path: 'my-organization',
        data: { breadcrumb: 'Mi organización' },
        loadChildren: () => import('./modules/my-organization/my-organization.module').then((m) => m.MyOrganizationModule),
      },
      {
        path: 'manage-certificate',
        data: { breadcrumb: { skip: true } },
        loadChildren: () => import('./modules/certificate/certificate.module').then((m) => m.CertificateModule),
      },
      {
        path: 'certificate-profiles',
        data: { breadcrumb: { skip: true } },
        loadChildren: () => import('./modules/certificate-profiles/certificate-profiles.module').then((m) => m.CertificateProfilesModule),
      },
      {
        path: 'my-certificate-profiles',
        data: { breadcrumb: { skip: true } },
        loadChildren: () => import('./modules/my-certificate-profiles/my-certificate-profiles.module').then((m) => m.MyCertificateProfilesModule),
      },
      {
        path: 'look-and-feel',
        data: { breadcrumb: 'Look & Feel' },
        loadChildren: () => import('./modules/look-and-feel/look-and-feel.module').then((m) => m.LookAndFeelModule),
      },
      {
        path: 'statistics',
        data: { breadcrumb: { skip: true } },
        loadChildren: () => import('./modules/statistics/statistics.module').then((m) => m.StatisticsModule),
      }
    ],
  },
];
