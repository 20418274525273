import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, ReplaySubject } from 'rxjs';
import { AccountService } from 'app/core/account';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ComparisonOperator, Query, Response, Result } from 'app/core/models';
import { environment } from 'environments/environment';
import { Notification, NotificationMode } from './notifications.types';
import { AuditOperations } from 'app/modules/audit-logs/audit-logs.types';
import { AuditLogsService } from 'app/modules/audit-logs/audit-logs.service';
import { UserService } from 'app/core/user';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  private _httpClientDirect: HttpClient;

  private _notifications: BehaviorSubject<Result<Notification>[]> =
    new BehaviorSubject<Result<Notification>[]>([]);

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _accountService: AccountService,
    private _userService: UserService,
    private _httpClient: HttpClient,
    private _auditLogsService: AuditLogsService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get notifications$(): Observable<Result<Notification>[]> {
    return this._notifications.asObservable();
  }

  set notifications(value: Result<Notification>[]) {
    this._notifications.next(value);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getNotifications(
    type: NotificationMode = NotificationMode.toRead
  ): Observable<Response<Notification>> {
    this._notifications.next(null);
    return combineLatest(
      [
        this._accountService.account$,
        this._userService.user$,
      ]).pipe(
      take(1),
      switchMap(([account, user]) => {
        const query: Query = {};
        query.keyValue = { accountUuid: account.accountUuid };
        query.filters = {
          status: { attributeValueList: [{ bool: type === NotificationMode.toRead }], comparisonOperator: ComparisonOperator.EQ },
          role: { attributeValueList: [account.selectedRole], comparisonOperator: ComparisonOperator.EQ },
          userEmail: { attributeValueList: [user.attributes.email], comparisonOperator: ComparisonOperator.EQ },
        };
        query.total = 100;
        query.page = 0;

        return this._httpClient
          .post<Response<Notification>>(
            `${environment.api.reportAndNotification}/notificationService`,
            query,
            {
              params: { type: 'artificial' },
            }
          )
          .pipe(tap((response) => this._notifications.next(response.results)));
      })
    );
  }

  getHtml(path: string): Observable<string> {
    // Generate the headers
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'text/html');

    return this._httpClientDirect
      .get(path, { headers, responseType: 'text' })
      .pipe(map((response) => response));
  }

  updateNotifications(): Observable<any> {
    return this.notifications$.pipe(
      take(1),
      switchMap((notifications) => {
        if (notifications?.length) {
          return forkJoin(
            notifications.map((notification) =>
              this.updateNotification(notification)
            )
          );
        }
      })
    );
  }

  updateNotification(notification: Result<Notification>): Observable<any> {
    return this.notifications$.pipe(
      take(1),
      switchMap((notifications) => {
        notification.element.status = false;

        return this._httpClient
          .put<{ message: string; id: string }>(
            `${environment.api.reportAndNotification}/notificationService/${notification.id}`,
            notification.element
          )
          .pipe(
            map((response) => {
              if (notifications) {
                const index = notifications.findIndex(
                  (item) => item.id === response.id
                );
                if (index > -1) {
                  notifications.splice(index, 1);
                  this._notifications.next(notifications);
                }
              }
              return response;
            }),
            switchMap((response) => {
              if (response.id) {
                return this._auditLogsService.createAuditLog(
                  AuditOperations.MARK_AS_READ,
                  { uuid: response.id }
                );
              }
              return null;
            })
          );
      })
    );
  }
}
