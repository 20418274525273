import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { UserService } from 'app/core/user';
import { AccountService } from 'app/core/account';
import { NavigationService } from 'app/core/navigation';
import { SnackbarService } from 'app/core/snackbar/snackbar.service';
import { AuthUtils } from 'app/core/auth';
import { StringUtils } from 'app/core/utils';
import { AccountUser } from 'app/modules/users/users.types';
import { environment } from 'environments/environment';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';

@Injectable()
export class AuthService {
  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _accountService: AccountService,
    private _snackbar: SnackbarService,
    private _navigation: NavigationService,
    private _splashService: FuseSplashScreenService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('WORKFLOW_ACCESS_TOKEN', token);
  }

  /**
   * Getter for access token
   */
  get accessToken(): string {
    return localStorage.getItem('WORKFLOW_ACCESS_TOKEN') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   */
  signIn(username: string, password: string): Observable<any> {
    // Remove blank spaces
    username = StringUtils.removeBlankSpaces(username).toLowerCase();
    password = StringUtils.removeBlankSpaces(password);
    let domain = document.location.hostname;
    domain = domain.split('.')[0];

    return this._httpClient
      .post(`${environment.api.authPy}/signInPy`, {
        username,
        password,
        domain,
      })
      .pipe(
        switchMap((user: any) => {
          // Store the access token
          this.accessToken = user.accessToken;

          // Store the user
          this._userService.user = {
            attributes: user.attributes,
            new: user.new,
          };

          // Return the user
          return of(user);
        })
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Clear all data
    AuthService._clear();
    this._userService.clear();
    this._accountService.clear();
    this._navigation.clear();

    // Sign out the user
    return from(Auth.signOut({ global: true })).pipe(
      switchMap(() => {
        // Reload the app
        location.reload();
        return of(true);
      })
    );
  }

  /**
   * Send account confirmation email
   */
  sendConfirmation(user: AccountUser): Observable<any> {
    // Send confirmation email
    return from(Auth.resendSignUp(user.userEmail, {'accountUuid': user.accountUuid})).pipe(
      switchMap((result) => {
        this._snackbar.showSuccess('¡Se ha enviado el correo de confirmación exitosamente!');
        return of(result);
      }),
      catchError((error) => {
        this._snackbar.showError(error?.message || 'Ha ocurrido un error inesperado');
        return of(false);
      })
    );
  }

  /**
   * Send a verification code to the given user
   */
  forgotPassword(email: string): Observable<any> {
    // Remove blank spaces
    email = StringUtils.removeBlankSpaces(email).toLowerCase();

    // Send verification code
    return from(Auth.forgotPassword(email)).pipe(
      switchMap((result) => of(result))
    );
  }

  /**
   * Confirm account with the given code
   */
  confirmAccount(email: string, code: string): Observable<any> {
    // Remove blank spaces
    email = StringUtils.removeBlankSpaces(email).toLowerCase();
    code = StringUtils.removeBlankSpaces(code);

    // Confirm account
    return from(Auth.confirmSignUp(email, code)).pipe(
      switchMap((result) => of(result))
    );
  }

  /**
   * Reset the password
   */
  resetPassword(email: string, code: string, password: string): Observable<any> {
    // Remove blank spaces
    email = StringUtils.removeBlankSpaces(email).toLowerCase();
    code = StringUtils.removeBlankSpaces(code);
    password = StringUtils.removeBlankSpaces(password);

    // Reset the password
    return from(Auth.forgotPasswordSubmit(email, code, password)).pipe(
      switchMap((result) => of(result))
    );
  }

  /**
   * Check the authentication status
   */
  checkAuthStatus(): Observable<boolean> {
    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expiration date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      this.clearAll();
      return of(false);
    }

    return of(true);
  }

  /**
   * Check the role status
   */
  checkRoleStatus(): Observable<boolean> {
    // Check the account availability
    return this._accountService.get().pipe(
      take(1),
      switchMap((account) => {
        // Check the role availability
        if (!account) {
          return of(false);
        }

        return of(true);
      })
    );
  }

  updateUser(user: AccountUser): Observable<AccountUser> {
    return this._httpClient.put<any>(`${environment.api.authPy}/user/${user.id}`, user.user)
      .pipe(
        map((response) => {
          this._snackbar.showSuccess(`Hola ${user.user.firstName}, el usuario ha sido actualizado correctamente.
          ${user.roles.length <= 0 ? 'Sin embargo, no tiene los permisos necesarios para autenticarse' +
            ' en este momento, por favor contacte al administrador del sistema.' : ''}`, 10000);
          const attributes = {
            ...user.user,
            email: response?.Attributes?.userEmail,
            accountUuid: response?.Attributes?.accountUuid,
            id: user.id
          };
          this._userService.user = {
            attributes: attributes,
            new: false,
          };
          return user;
        })
      );
  }

  microsoftUserLogin(token: string): Observable<any> {
    this._splashService.show();
    let domain = document.location.hostname;
    domain = domain.split('.')[0];

    return this._httpClient.post<any>(`${environment.api.authPy}/verifyADUser`, {
      token: token,
      domain
    })
      .pipe(
        switchMap((user: any) => {
          this.accessToken = user.accessToken;
          this._userService.user = {
            attributes: user.attributes,
            new: user.new,
          };
          this._splashService.hide();
          return of(user);
        })
      );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private clearAll(): void {
    AuthService._clear();
    this._userService.clear();
    this._accountService.clear();
    this._navigation.clear();
  }

  /**
   * Clear the auth data
   *
   * @private
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private static _clear(): void {
    // Remove the access token from the storage
    localStorage.removeItem('WORKFLOW_ACCESS_TOKEN');
  }
}
