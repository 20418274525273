<div class="scroll-filter-bar flex flex-auto border-b overflow-x-auto">

  <!-- Open Filter Menu Icon-->
  <div class="relative flex items-center">
    <div
      class="sticky pl-5 pr-7 py-1 left-0 z-30 bg-gradient-to-r from-white via-white to-transparent"
      [ngClass]="{
        'dark:from-gray-900 dark:via-gray-900': dark,
        'dark:from-gray-800 dark:via-gray-800': !dark
      }"
    >
      <button
        #filtersPanelOrigin
        mat-icon-button
        matTooltip="Filtrar"
        matTooltipPosition="above"
        (click)="openPanel()"
      >
        <mat-icon svgIcon="filter_list"></mat-icon>
      </button>
    </div>

    <div class="flex w-full -ml-3">
      <ng-container *ngFor="let item of values | keyvalue">
        <ng-container *ngIf="(isNotNull(item.value) && item.value) || item.value === false || item.value === 0">
          <div class="ml-2">
            <mat-chip-list>

              <!-- If item value is just one value -->
              <ng-container *ngIf="!isArrayType(item.value)">
                <mat-chip
                  [removable]="true"
                  [selectable]="true"
                  (removed)="removeItem(item.key)"
                  (click)="openPanel()"
                >
                  <div class="whitespace-nowrap">
                    {{ translator.translate(item.key) }}:

                    <!-- If value is not boolean neither an object -> the object is a date -->
                    <ng-container *ngIf="(!isBooleanType(item.value) && !isObjectType(item.value)) || isDateType(item.value)">
                      {{ isDateType(item.value) ? (item.value | date: "MMMM d, y") : translator.translate(item.value) }}
                    </ng-container>

                    <!-- If value is boolean -->
                    <ng-container *ngIf="isBooleanType(item.value)">
                      <mat-icon *ngIf="item.value" class="icon-size-4 align-middle" svgIcon="heroicons_solid:check"></mat-icon>
                      <mat-icon *ngIf="!item.value" class="icon-size-4 align-middle" svgIcon="heroicons_solid:x"></mat-icon>
                    </ng-container>

                    <!-- If the value is dictionary/object type -->
                    <ng-container *ngIf="isObjectType(item.value) && !isDateType(item.value)">
                      <div class="inline-flex">
                        <ng-container *ngFor="let subValue of item.value | keyvalue: order; let first = first">
                          {{ first ? "" : subValue.value ? "&nbsp; - &nbsp;" : "" }}
                          {{ canBeDate(subValue.value) ? (toDate(subValue.value) | date: "MMMM d, y") : subValue.value }}
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>

                  <mat-icon class="icon-size-5" matChipRemove svgIcon="heroicons_solid:minus-circle"></mat-icon>
                </mat-chip>
              </ng-container>

              <!-- If item value is an array of values - Show a chip for each value -->
              <ng-container *ngIf="isArrayType(item.value)">
                <div class="inline-flex">
                  <ng-container *ngFor="let subitem of item.value">
                    <mat-chip
                      [removable]="true"
                      [selectable]="true"
                      (removed)="filters.emit(); removeSubItem(item.key, subitem)"
                      (click)="openPanel()"
                    >
                      <div class="whitespace-nowrap">
                        {{ translator.translate(item.key) }}:
                        {{ isDateType(subitem) ? (subitem | date: "MMMM d, y") : translator.translate(subitem) }}
                      </div>
                      <mat-icon class="icon-size-5" matChipRemove svgIcon="heroicons_solid:minus-circle"
                      ></mat-icon>
                    </mat-chip>
                  </ng-container>
                </div>
              </ng-container>
            </mat-chip-list>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<!-- Filter Panel -->
<ng-template #filtersPanel>
  <div
    class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg"
    [@expandCollapse]="isExpanded"
  >

    <!-- Header -->
    <div class="flex flex-shrink-0 items-center py-1 pr-4 pl-4 bg-white border dark:bg-gray-700 dark:text-white">
      <div class="sm:hidden -ml-1 mr-3">
        <button mat-icon-button (click)="closePanel()">
          <mat-icon class="icon-size-5 text-current" svgIcon="heroicons_solid:x"></mat-icon>
        </button>
      </div>
      <div class="text-lg font-medium leading-10 text-current">Filtrar por</div>
    </div>

    <!-- Content -->
    <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">

      <!-- filters -->
      <ng-container>
        <div class="flex flex-col flex-auto items-center justify-center sm:justify-start pt-3 pb-1 px-3">
          <ng-content></ng-content>
        </div>
      </ng-container>
    </div>

    <!-- Footer -->
    <div class="flex flex-shrink-0 items-center py-1 pr-4 pl-6 bg-gray-50 border-t dark:bg-gray-700">
      <div class="ml-auto">
        <button mat-icon-button matTooltip="Filtrar" (click)="setEndDate(); filters.emit(); closePanel()">
          <mat-icon class="transform icon-size-5 rotate-90 text-primary dark:text-white" svgIcon="heroicons_solid:paper-airplane"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>
