import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api';

import { navigation as navigationData } from './data';

@Injectable({
  providedIn: 'root',
})
export class NavigationMockApi {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  private readonly _navigation = navigationData;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Navigation - GET
    // -----------------------------------------------------------------------------------------------------

    this._fuseMockApiService
      .onGet('api/common/navigation')
      .reply(({ request }) => {
        // Get available queries
        const role = request.params.get('role');

        // Initialize the navigation
        let navigation = [];

        // Get the navigation for the role
        if (role) {
          navigation = cloneDeep(this._navigation[role.toLowerCase()]);
        }

        // Return the response
        return [200, navigation];
      });
  }
}
