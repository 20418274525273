import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MsalService } from '@azure/msal-angular';
import { UserService } from 'app/core/user';
import { NavigationService } from 'app/core/navigation';
import { Account, AccountService, Role } from 'app/core/account';
import { Entity } from 'app/modules/entities/entities.types';
import { roles, rolesWithSuperAdmin } from 'app/modules/users/users.constants';
import { ProfileService } from './user.service';
import { NotificationsService } from '../notifications/notifications.service';
import { LoginType, LookAndFeel } from 'app/modules/look-and-feel/look-and-feel.types';
import { LookAndFeelService } from 'app/modules/look-and-feel/look-and-feel.service';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
})
export class UserComponent implements OnInit, OnDestroy {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  @Input() tooltip: string;

  user: any;
  account: Account;
  parent: Entity;

  lookAndFeel: LookAndFeel;

  private _roles: Role[];

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private _userService: UserService,
    private _accountService: AccountService,
    private _profileService: ProfileService,
    private _navigation: NavigationService,
    private _notificationsService: NotificationsService,
    private _msalService: MsalService,
    private _lookAndFeelService: LookAndFeelService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Get the user
    this._userService.user$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user) => {
        // Store the user
        this.user = user;

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    // Get the account
    this._accountService.account$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((account) => {
        // Store the account
        this.account = account;
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    // Get the application roles
    this._accountService.roles$
      .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((rolesList) => {
          // Store the roles
          this._roles = rolesList;

          // Mark for check
          this._changeDetectorRef.markForCheck();
        });

    //Get the parent account
    if (this.account.parentAccountUuid !== '1') {
      this._profileService.getEntityByUuid(this.account.parentAccountUuid)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((response) => {
          this.parent = response;
        });
    }

    this._lookAndFeelService.lookAndFeel$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((lookAndFeel) => {
        this.lookAndFeel = lookAndFeel;
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Change the role
   */
  changeRole(role: roles | rolesWithSuperAdmin): void {
    // Update the selected role
    this.account.selectedRole = role;

    // Store the account
    this._accountService.account = this.account;

    this._notificationsService.getNotifications().subscribe();

    // Navigate to the default page
    this._accountService.navigate(role);
  }

  /**
   * Change the account
   */
  changeAccount(): void {
    // Navigate to select entity
    this._router.navigate(['select-entity']);

    // Clear the selected account
    this._accountService.clear();

    // Clear the navigation data
    this._navigation.clear();
  }

  /**
   * My organization label
   */
  myOrganization(): void {
    // Navigate to select entity
    this._router.navigate(['/my-organization']);

  }

  /**
   * Sign out
   */
  signOut(): void {
    if (this.lookAndFeel?.login?.type === LoginType.MICROSOFT_OAUTH_2_0) {
      this._msalService.logoutRedirect();
    } else {
      this._router.navigate(['/sign-out']);
    }
  }

  /**
   * Get role by value
   */
  getRoleByValue(value: string): Role {
    return this._roles.find((item) => item.value === value);
  }
}
