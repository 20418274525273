export class DateUtils {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  private static readonly _monthNames = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Apply a nice format to the given date string
   */
  public static getNiceDateString(dateString: string): string {
    const date: Date = this.getBogotaDate(dateString);

    return (
      date.getDate() +
      ' de ' +
      this._monthNames[date.getMonth()] +
      ' del ' +
      date.getFullYear()
    );
  }

  /**
   * Apply a nice format to the given time date string
   */
  public static getNiceTimeString(dateString: string): string {
    const date: Date = this.getBogotaDate(dateString);
    return date.toLocaleTimeString();
  }

  /**
   * Increment the current date by the given days
   */
  public static getDateIncrementedByDays(days: number): Date {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() + days);
  }

  /**
   * Get the America/Bogota date time
   */
  public static getBogotaDate(dateString: string): Date {
    const date: Date = new Date(dateString);

    return new Date(
      date.toLocaleString('en-US', { timeZone: 'America/Bogota' })
    );
  }

  /** Subtract two dates and return the diff in years, months, weeks and days */
  public static subtractDates(
    from: Date,
    to: Date
  ): { years?: number; months?: number; weeks?: number; days?: number } {
    const years = this.subtractInYears(from, to);
    const months = this.subtractInMonths(from, to);
    const weeks = this.subtractInWeeks(from, to);
    const days = this.subtractInDays(from, to);

    return { years, months, weeks, days };
  }

  public static subtractInYears(from: Date, to: Date): number {
    return new Date(to).getFullYear() - new Date(from).getFullYear();
  }

  public static subtractInMonths(from: Date, to: Date): number {
    const fromY = new Date(from).getFullYear();
    const toY = new Date(to).getFullYear();
    const fromM = new Date(from).getMonth();
    const toM = new Date(to).getMonth();

    return fromM + 12 * fromY - (toM + 12 * toY);
  }

  public static subtractInWeeks(from: Date, to: Date): number {
    const toDate = new Date(to).getTime();
    const fromDate = new Date(from).getTime();

    return parseInt(String((toDate - fromDate) / (24 * 3600 * 1000 * 7)), 10);
  }

  public static subtractInDays(from: Date, to: Date): number {
    const toDate = new Date(to).getTime();
    const fromDate = new Date(from).getTime();

    return Math.floor((toDate - fromDate) / (24 * 3600 * 1000));
  }

  public static addHours(date: Date, hours: any): Date {
    const newDate = new Date(date);
    newDate.setHours(date.getHours() + hours);

    return newDate;
  }

  public static dDMMYYYYToDate(date: string): Date {
    if (!date) {
      return null;
    }
    return new Date(
      +date.substr(4, 4),
      Number(date.substr(2, 2)) - 1,
      +date.substr(0, 2)
    );
  }

  public static dateToDDMMYYYY(date: Date): number {
    let month: string | number = date.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }
    let dateOfMonth: string | number = date.getDate();
    if (dateOfMonth < 10) {
      dateOfMonth = '0' + dateOfMonth;
    }
    const year = date.getFullYear();
    return Number(dateOfMonth + '' + month + '' + year);
  }

  public static isValidDate(date: Date): boolean {
    return !isNaN(date.getMonth());
  }

  public static setLastHour(date: Date): Date {
    date.setHours(23, 59, 59);
    return date;
  }
}
