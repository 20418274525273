import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api';

import { documents as documentsData } from './data';

@Injectable({
  providedIn: 'root',
})
export class DocumentsMockApi {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  private _documents: any[] = documentsData;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Fields - GET
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onGet('api/documents', 3000)
      .reply(({ request }) => {
        // Get available queries
        const id = request.params.get('id');

        // Clone the documents
        const documents = cloneDeep(this._documents);

        // Filter the document
        const document = documents.find((item) => item.uuid === id);

        // Return the response
        return [200, document];
      });
  }
}
